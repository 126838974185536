@import '../../styles/variables.scss';

#end-of-document {
  width: 100px;
  height: 8px;
  position: fixed;
  bottom: 2px;
}

.chat-list {
  margin-top: 110px;
}

.chat-header {
  min-width: $min-width;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 4fr;
  width: 100%;
  z-index: 100;
  border-bottom: 4px solid #3182ce;
  font-size: 15.4px;
  border-radius: 0;
  background-color: white;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);

  .chat-header__btn_my,
  .chat-header__btn_all,
  .chat-header__btn_new-payment,
  .chat-header__search-input,
  .chat-header__btn {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin-right: 8px;
    transition: 0.3s;
    border: 1px solid #63b3ed;
    border-radius: 8px;
    color: #2b6cb0;
    padding: 4px 8px;
    font-size: 0.875rem;

    .credit-card-icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background-color: #296db3;
      -webkit-mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
      mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
    }

    .handshake-icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background-color: #296db3;
      -webkit-mask: url(../../../public/icons/handshake-regular.svg) no-repeat center;
      mask: url(../../../public/icons/handshake-regular.svg) no-repeat center;

      &.chat-header__btn_active {
        background-color: white;
      }
    }

    .multy-chat-icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background-color: #296db3;
      -webkit-mask: url(../../../public/icons/users-multy.svg) no-repeat center;
      mask: url(../../../public/icons/users-multy.svg) no-repeat center;

      &.chat-header__btn_active {
        background-color: white;
      }
    }

    .icon {

    }

    &.chat-header__btn_active {
      background: #3182ce;
      color: white;
    }
  }

  .chat-header__btn_new-payment {
    margin: 0 0 0 auto;
  }

  .chat-header__btn_all {
    width: 42px;
  }

  .chat-header__btn_my{
    width: 73px;
  }

  .chat-header__btn_new-payment{
    width: 73px;
  }

  .chat-header__search-input{
    width: 60%;

  }
}

.warn-chats {
  min-width: $min-width;
  position: fixed;
  top: 48px;
  left: 0;
  display: flex;
  grid-template-columns: 1fr 1fr 4fr;
  width: 100%;
  z-index: 100;
  border-bottom: 4px solid #3182ce;
  font-size: 15.4px;
  border-radius: 0;
  background-color: white;
  padding: 12px 12px 8px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  overflow-y: auto;
  overflow-x: scroll;
  // transform: rotate(-90deg);
  // transform-origin: right top;

  .warn-chats__btn_warn-chat,
  .warn-chats__btn {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin-right: 8px;
    transition: 0.3s;
    border: 1px solid #63b3ed;
    border-radius: 8px;
    padding: 4px 6px;
    font-size: 0.875rem;
    // max-width: 140px;
    // transform: rotate(90deg);
    // transform-origin: right top;

    .warn-chats__btn_p{
      max-height: 30px;
      white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      padding-left: 6px;
    }

    .warn-chats__warn_level{
      border-radius: 5px;
      min-width: 10px;
      min-height: 10px;
      font-size: 11px;
      padding: 3px;
      display: inline-table;
    }

    .warn-chats__warn_level_1{
      color: #ffffff;
      background-color: #f18a8a;
    }

    .warn-chats__warn_level_2{
      color: #ffffff;
      background-color: #cb95dd;
    }

    .warn-chats__warn_level_3{
      color: #ffffff;
      background-color: #f2ab77;
    }

    .credit-card-icon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      margin-left: 2px;
      background-color: #296db3;
      -webkit-mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
      mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
    }

    .flag-icon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      margin-left: 2px;
      background-color: #f50f0f;
      background: url(../../../public/icons/flag-svgrepo-com.svg) no-repeat center;
    }

    .flag-icon-img {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      margin-left: 2px;

      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

    }

    &.warn-chat__btn_active {
      background: #3182ce;
      color: white;
    }
  }

  .warn-chat__btn_new-payment {
    margin: 0 0 0 auto;
  }
}

.chat__btn_next-page {
  margin: 12px auto;
  background-color: #3182ce;
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  font-weight: bold;
}

.chat {
  background: white;
  width: 100%;
  padding: 4px 8px;
  display: grid;
  /*для фоток узеров*/
  /*grid-template-columns: 60px minmax(50%, 95%);*/
  grid-gap: 8px;
  margin-bottom: 8px;
  max-height: 108px;
  overflow: hidden;
  font-size: 0.875rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;

  &.chat_active {
    /*border: 2px solid dodgerblue;*/
    border: 1px solid #bee3f8;
    background: #ebf8ff;
  }

  .chat__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid lightblue;
    }

    .chat__btn_in {
      margin: 0 auto;
      background-color: skyblue;
      color: white;
      border-radius: 8px;
      padding: 2px 12px;
      font-weight: bold;
      float: right;
    }
  }

  h4 {
    font-weight: bold;
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;
  }

  p {
    bottom: 2px;
    position: relative;
  }

  .chat__msg-count {
    padding: 2px;
    color: firebrick;
    font-weight: bold;
    font-size: 16px;
    margin-right: 2px;
  }

  .chat__msg-counter {
    @include flex-center;
    text-align: center;
    align-items: center;
    align-content: center;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding: 2px;
    color: white;
    background: #2b6cb0;
    font-weight: bold;
    font-size: 12px;
    margin-left: 2px;
    cursor: pointer;
  }

  .chat__msg-prefix {
    display: inline-block;
    position: relative;

    &.favorite-client {
      img {
        background: yellow;
      }
    }

    &.multy-chat {
      img {
        background: yellow;
      }
    }


    .chat__msg-prefix_days{
      color: #999999;
    }

    .credit-card {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      background-color: #296db3;
      -webkit-mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
      mask: url(../../../public/icons/credit-card-regular.svg) no-repeat center;
    }

    button {
      padding: 2px 6px;
      border: 1px solid #a5b5cb;
      border-radius: 8px;
      margin-left: 4px;

      .handshake {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        background-color: #a5b5cb;
        -webkit-mask: url(../../../public/icons/handshake-regular.svg) no-repeat center;
        mask: url(../../../public/icons/handshake-regular.svg) no-repeat center;

        &.favorite-client {
          background-color: #296db3;
        }
      }

      .people-multy {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        background-color: #a5b5cb;
        -webkit-mask: url(../../../public/icons/users-multy.svg) no-repeat center;
        mask: url(../../../public/icons/users-multy.svg) no-repeat center;

        &.multy-chat {
          background-color: #296db3;
        }
      }
    }

  }

  article {
    .chat__user-description {
      @include flex-center;
      justify-content: space-between;
      margin-bottom: 2px;

      .chat__user-desc-text {
        @include flex-center;
        justify-content: start;
        width: 55%;
        cursor: pointer;
      }

      .chat__user-actions {
        @include flex-center;
        flex-direction: row;

        &.visible-btns {
          button {
            @include flex-center;
          }
        }

        button {
          @include flex-center;
          width: 28px;
          height: 28px;
          border: 1px solid dodgerblue;
          border-radius: 50%;
          display: none;
          margin-left: 4px;
          background: lightcyan;

          &.user-actions__btn_menu {
            display: flex;
          }
        }
      }
    }
    .chat__user-sub-desc-text {
      overflow: hidden;
      cursor: pointer;
      font-size: 10px;
      color:grey;
      max-height: 12px;
      margin-top: -3px;
    }

    .chat_msg__text {
      max-height: 40px;
      overflow: hidden;
      cursor: pointer;
    }

    .chat_msg__date {
      display: flex;
      justify-content: end;
      cursor: pointer;
      float: right;
      margin-right: 4px;
      font-size: 0.57rem;
      font-style: italic;
      width: 100%;
    }

    .button-cancel-message{
      z-index: 3;
      position: absolute;
    }
  }
}
